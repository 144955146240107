












































import { Component, Vue, PropSync } from 'vue-property-decorator';
import LinkStrategyModerateRequestSentViewModel
  from '@/vue-app/view-models/components/strategy-moderate-wealth/link-goal/link-strategy-moderate-request-sent-view-model';

@Component({ name: 'LinkStrategyModerateRequestSent' })
export default class LinkStrategyModerateRequestSent extends Vue {
  @PropSync('goalName', { type: String, required: true })
  synced_goal_name!: string;

  strategy_request_view_model = Vue.observable(new LinkStrategyModerateRequestSentViewModel(this));

  created() {
    this.strategy_request_view_model.initialize();
  }
}
